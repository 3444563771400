<template>
   <div class="col-12" :class="showEdit ? 'px-0' : 'col-md-6 px-1 ps-md-0'">
      <div class="card mb-1" :class="index % 4 == 0 || index % 4 == 3 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-sm">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ pdv.nome }}</h1>
                  <p class="font-10 mb-1 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ pdv.id == null ? '?' : pdv.id }}</span>
                     <span class="ms-3">
                        <i class="far fa-tag font-8 me-1"></i>
                        <span>{{ pdv.numeroPDV == null || String(pdv.numeroPDV).trim().length == 0 ? 'Sem número' : pdv.numeroPDV }}</span>
                     </span>
                  </p>
                  <p class="font-12 mb-0 limitador-2">
                     <i class="far fa-wifi color-theme font-10 me-1"></i><strong class="subtitle me-1"> Tipo:</strong>
                     <span>{{ pdv.isOffline ? 'Offline' : 'Online' }}</span>
                  </p>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Editar lojas')">
                  <div class="btn-icone color-theme" v-if="showEdit">
                     <span @click="editar"><i class="far fa-pen d-block"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash d-block"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'PDV',
   props: ['pdv', 'index', 'showEdit'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario
      })
   },
   methods: {
      editar : function () {
         this.$emit('editar', this.pdv)
      },
      remover : function () {
         this.$emit('remover', this.index)
      }
   }
}

</script>

<style scoped>

.col-12.text-red h1,
.col-12.text-red .color-theme {
   color: #c23e4a !important;
}

</style>