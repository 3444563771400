<template>
   <div class="col-12 px-0 mb-1" :class="togglePDVs ? 'main-container' : ''" ref="loja">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-sm-start cursor-pointer" @click="togglePDVs = !togglePDVs;">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ loja.nomeFantasia }}</h1>
                        <p class="font-10 mb-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ loja.id }}</span>
                           <span class="ms-3">
                              <i class="far fa-tag font-9 me-1"></i>
                              <span>{{ loja.numeroLoja == null || String(loja.numeroLoja).trim().length == 0 ? 'Sem número' : loja.numeroLoja }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col-6 col-lg-4">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-map-marker-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Endereço:</strong>
                           <span> {{ loja.endereco == null || loja.endereco.rua == null || String(loja.endereco.rua).trim().length == 0 ? 'Sem endereço' : (loja.endereco.rua +', '+ loja.endereco.numero +' - '+ loja.endereco.bairro) }}</span>
                        </p>
                     </div>
                     <div class="col-6 col-lg-4">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-address-card color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> CNPJ:</strong>
                           <span> {{ loja.cnpj == null || String(loja.cnpj).trim().length == 0 ? 'Sem CNPJ' : loja.cnpj }}</span>
                        </p>
                     </div>
                     <div class="col-lg-4 d-none d-lg-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1"> Venc. Certificado:</strong>
                           <span> {{ loja.dataVencimentoCertificado == null ? 'Sem certificado' : String(loja.dataVencimentoCertificado.dayOfMonth).padStart(2, '0') +'/'+ String(loja.dataVencimentoCertificado.monthValue).padStart(2, '0') +'/'+ loja.dataVencimentoCertificado.year }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Editar lojas')">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-pen"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="sub-container" v-if="togglePDVs">
         <div class="col-12 mb-3">
            <h1 class="font-15 mt-1 mb-0"><i class="fas fa-desktop font-12 color-theme me-2"></i>Caixas</h1>
         </div>

         <pdv v-for="(pdv, index) in loja.pdvs" :key="index" :pdv="pdv" :index="index" :showEdit="false" @remover="removerPDV($event)" />
         <div class="col-12 mt-4 mb-5 text-center" v-if="loja.pdvs.length == 0">
            <i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
            <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum caixa encontrado</h4>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import pdv from '@/components/configuracoes/lojas/PDV.vue'

export default {
   name: 'Loja',
   props: ['loja', 'index'],
   data: function () {
      return {
         togglePDVs: false
      }
   },
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   components: {
      pdv
   },
   methods: {
      editar : function () {
         this.$emit('editar', this.loja)
      },
      removerPDV : function (index) {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir caixa?',
            text: 'O caixa será permanentemente excluído.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`
   
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
   
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoes/deletePDV',
                  params: {
                     id: this.loja.pdvs[index].id
                  }
               }).then(() => {
                  this.loja.pdvs.splice(index, 1)
   
                  this.$toast.fire({
                     icon: 'success',
                     title: 'Caixa excluído!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')
   
                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir loja?',
            text: 'A loja e seus caixas serão excluídos.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoes/deleteLoja',
                  params: {
                     id: this.loja.id
                  }
               }).then(() => {
                  this.$refs.loja.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Loja excluída!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>