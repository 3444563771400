<template>
   <div class="col-12 px-0">
      <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col cursor-pointer" @click="estoque.habilitado = !estoque.habilitado">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ estoque.nome }}</h1>
                  <p class="font-10 mb-0 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ estoque.id }}</span>
                  </p>
               </div>
               <div class="w-max-content text-center font-16">
                  <div class="form-check mb-0">
                     <input class="form-check-input" type="checkbox" :id="'loja'+ estoque.id" :checked="estoque.habilitado" v-model="estoque.habilitado">
                     <label class="form-check-label" :for="'loja'+ estoque.id"></label>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'CheckEstoque',
   props: ['estoque', 'index']
}

</script>